import Typography from '@material-ui/core/Typography'
import React, { FC } from 'react'

import Wrapper from '../UI/Wrapper'
import { OverviewSection } from './styles'

interface FeatureOverviewProps {
  headline: string
  body: string
  isTech?: boolean
  theme?: 'Dark' | 'Light' | 'White'
  className?: 'bodyColumns' | 'singleColumn'
}

const FeatureOverview: FC<FeatureOverviewProps> = ({
  headline,
  body,
  theme = 'Light',
  className = 'bodyColumns',
  isTech = false,
}) => (
  <OverviewSection className={`theme${theme}`} isTech={isTech}>
    <Wrapper>
      <Typography className="subhead" component="h2" variant="h3">
        {headline}
      </Typography>
      <Typography className={className} component="p" variant="body1">
        {body}
      </Typography>
    </Wrapper>
  </OverviewSection>
)

export default FeatureOverview
