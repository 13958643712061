import styled from 'styled-components'

import { rspTheme } from '../../styles/rspTheme'

export const OverviewSection = styled.section<{ isTech: boolean }>`
  background-color: ${({ isTech }) =>
    isTech ? '#6B4A8114' : rspTheme.palette.primary.background};
  padding: 84px 0 96px;

  .subhead {
    color: ${rspTheme.palette.primary.main};
    font-size: 3.4rem;
    margin-bottom: 18px;
  }

  .bodyColumns {
    columns: 2 auto;
    margin-bottom: 16px;
    column-gap: 24px;
  }

  &.themeWhite {
    background-color: transparent;
  }

  &.themeDark {
    background-color: ${rspTheme.palette.primary.dark};
    color: #ffffff;

    .subhead {
      color: inherit;
    }
  }

  @media (max-width: ${rspTheme.breakpoints.values.smmd}px) {
    padding: 48px 0;

    .subhead {
      font-size: 2.8rem;
    }

    .bodyColumns {
      columns: unset;
    }
  }
`
